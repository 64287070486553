import { createRouter, createWebHistory } from 'vue-router'
import { isAuthenticated } from '@/services/auth'
import LoginView from '@/views/LoginView'
import StatusRegister from '@/views/StatusRegister'
import DashboardAgencia from '@/views/DashboardAgencia'
import AgenciaHome from '@/views/Dashboard/AddEmisora'
import DashboardGrupoRadio from '@/views/DashboardGrupoRadio'
import DashboardAnunciante from '@/views/DashboardAnunciante'
import CreateAdvertiser from '@/views/Dashboard/CreateAdvertiser'
import CreateAccount from '@/views/Dashboard/CreateAccount'

const routes = [
  {
    path: '/',
    redirect: {
      name: 'LoginView'
    }
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/password-recovery',
    name: 'PasswordRecoveryView',
    component: () => import('../views/PasswordRecoveryView.vue')
  },
  {
    path: '/password-recovered',
    name: 'PasswordRecoveredView',
    component: () => import('../views/PasswordRecoveredView.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPasswordView',
    component: () => import('../views/ResetPasswordView.vue'),
    props: true
  },
  {
    path: '/set-password/:token',
    name: 'SetPasswordView',
    component: () => import('../views/ResetPasswordView.vue'),
    props: true
  },
  {
    path: '/tokeninvalid/:token',
    name: 'TokenInvalid',
    component: () => import('@/views/TokenError.vue'),
    props: true
  },
  {
    path: '/register',
    name: 'StatusRegister',
    component: StatusRegister,
    props: { id: 1 }
  },
  {
    path: '/ca/rd/:token',
    name: 'DetailCampaign',
    component: () => import('../views/OrderDetail')
  },
  {
    path: '/account/activated/:token',
    name: 'AccountActivated',
    component: () => import('../views/AccountActivated'),
    props: true
  },
  {
    path: '/radio/correo',
    name: 'RadioCorreo',
    component: () => import('@/views/Emails/CorreosAgenciaAnunciantes/VerificaCuenta.vue')
  },
  {
    path: '/agencia',
    name: 'DashboardAgencia',
    component: DashboardAgencia,
    redirect: {
      name: 'DashboardHomeAgencia'
    },
    children: [
      {
        path: '/agencia/register',
        name: 'RegisterAgencia',
        component: () => import('@/views/Dashboard/TokenAgencyView')
      },
      {
        path: '/agencia',
        name: 'DashboardHomeAgencia',
        // component: () => import('@/views/Dashboard/AgencyHome/AgencyHome')
        component: () => import('@/views/Dashboard/AgencyBookingCampaingView.vue')
      },
      {
        path: '/agencia/create/advertiser',
        name: 'CreateAdvertiser',
        component: CreateAdvertiser
      },
      {
        path: '/agencia/profile',
        name: 'GroupProfileAgencia',
        component: () => import('@/views/Dashboard/profiles/GroupProfile')
      },
      {
        path: '/agencia/profile/user',
        name: 'AgenciaProfileUser',
        component: () => import('@/views/Dashboard/profiles/ProfileUser')
      },
      {
        path: '/agencia/detail/anunciante/:id',
        name: 'DetailAnunciante',
        component: () => import('@/views/Dashboard/detailAnunciante/ListAnunciante')
      },
      {
        path: '/agencia/booking-campaign',
        name: 'AgencyBookingCampaingView',
        component: () => import('@/views/Dashboard/AgencyBookingCampaingView.vue')
      },
      {
        path: '/agencia/create/campaign',
        name: 'AgencyCreateCampaign',
        component: () => import('@/views/Dashboard/campaignPlanning/DataCampaign.vue')
      },
      {
        path: '/agencia/edit/campaign/:id',
        name: 'AgencyEditCampaign',
        component: () => import('@/views/Dashboard/campaignPlanning/DataCampaign.vue')
      },
      {
        path: '/agencia/detail/campaign/:id',
        name: 'AgencyDetailCampaign',
        component: () => import('@/views/Dashboard/detailCampaing/DetailCampaing.vue')
      },
      {
        path: '/agencia/create/factura',
        name: 'CreateFactura',
        component: () => import('@/views/Dashboard/createFactura/CreateFacturaView.vue')
      },
      {
        path: '/agencia/list',
        name: 'AgencyListView',
        component: () => import('@/views/Dashboard/AgencyListView.vue')
      },
      {
        path: '/agencia/discount-list',
        name: 'AgenciaDiscountListView',
        component: () => import('@/views/Dashboard/AgenciaDiscountListView.vue')
      },
      {
        path: '/agencia/reports',
        name: 'AgencyReportView',
        component: () => import('@/views/Dashboard/AgencyReportsView.vue')
      }
    ]
  },
  {
    path: '/radio',
    name: 'DashboardGrupoRadio',
    component: DashboardGrupoRadio,
    redirect: {
      name: 'DashboardHomeGrupoRadio'
    },
    children: [
      {
        path: '/radio/fctu',
        name: 'RegisterRdddadio',
        component: () => import('@/components/createFactura/SetFacturaAdvertiser')
      },
      {
        path: '/radio/register',
        name: 'RegisterRadio',
        component: () => import('@/views/Dashboard/TokenRadioView')
      },
      {
        path: '/radio',
        name: 'DashboardHomeRadio',
        component: () => import('@/views/Dashboard/RadioCampaingListView.vue')
        // component: () => import('@/views/Dashboard/RadioHome/RadioHome')
      },
      {
        path: '/radio/create/station',
        name: 'CreateStation',
        component: AgenciaHome
      },
      {
        path: '/radio/edit/station/:id',
        name: 'EditStation',
        component: AgenciaHome
      },
      {
        path: '/radio/list',
        name: 'RadioListView',
        component: () => import('@/views/Dashboard/RadioListView')
      },
      {
        path: '/radio/detail/emisora',
        name: 'DetailEmisora',
        component: () => import('@/views/Dashboard/detailEmisora/ListEmisora')
      },
      {
        path: '/radio/profile',
        name: 'GroupProfileRadio',
        component: () => import('@/views/Dashboard/profiles/GroupProfile')
      },
      {
        path: '/radio/profile/user',
        name: 'RadioProfileUser',
        component: () => import('@/views/Dashboard/profiles/ProfileUser')
      },
      {
        path: '/radio/campaign-list',
        name: 'RadioCampaingListView',
        component: () => import('@/views/Dashboard/RadioCampaingListView.vue')
      },
      {
        path: '/radio/discount-list',
        name: 'RadioDiscountListView',
        component: () => import('@/views/Dashboard/RadioDiscountListView.vue')
      },
      {
        path: '/radio/detail/campaign/:id',
        name: 'RadioDetailCampaing',
        component: () => import('@/views/Dashboard/detailCampaingEmisora/DetailCampaing.vue')
      }
    ]
  },
  {
    path: '/anunciante',
    name: 'DashboardAnunciante',
    component: DashboardAnunciante,
    redirect: {
      name: 'DashboardHomeAnunciante'
    },
    children: [
      {
        path: '/anunciante/register',
        name: 'RegisterAdvertiser',
        component: () => import('@/views/Dashboard/TokenAdvertiserView')
      },
      {
        path: '/anunciante/create/account',
        name: 'CreateAccount',
        component: CreateAccount
      },
      {
        path: '/anunciante',
        name: 'DashboardHomeAnunciante',
        component: () => import('@/views/Dashboard/AgencyBookingCampaingView.vue')
        // component: () => import('@/views/Dashboard/AnuncianteHome/AnuncianteHome')
      },
      {
        path: '/anunciante/profile',
        name: 'GroupProfileAnunciante',
        component: () => import('@/views/Dashboard/profiles/GroupProfile')
      },
      {
        path: '/anunciante/profile/user',
        name: 'AnuncianteProfileUser',
        component: () => import('@/views/Dashboard/profiles/ProfileUser')
      },
      {
        path: '/anunciante/detail/account',
        name: 'DetailAccount',
        component: () => import('@/views/Dashboard/DetailAccountAdvertiser/AccountAdvertiser')
      },
      {
        path: '/anunciante/list',
        name: 'AdvertiserListView',
        component: () => import('@/views/Dashboard/AdvertiserListView')
      },
      {
        path: '/anunciante/discount-list',
        name: 'AnuncianteDiscountListView',
        component: () => import('@/views/Dashboard/AgenciaDiscountListView.vue')
      },
      {
        path: '/anunciante/create/campaign',
        name: 'AnuncianteCreateCampaign',
        component: () => import('@/views/Dashboard/campaignPlanning/DataCampaign.vue')
      },
      {
        path: '/anunciante/booking-campaign',
        name: 'AnuncianteBookingCampaingView',
        component: () => import('@/views/Dashboard/AgencyBookingCampaingView.vue')
      },
      {
        path: '/anunciante/detail/campaign/:id',
        name: 'AnuncianteDetailCampaign',
        component: () => import('@/views/Dashboard/detailCampaing/DetailCampaing.vue')
      },
      {
        path: '/anunciante/edit/campaign/:id',
        name: 'AdvertiserEditCampaign',
        component: () => import('@/views/Dashboard/campaignPlanning/DataCampaign.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

// console.log(isAuthenticated())

// Global Guard navigation
router.beforeEach((to, from, next) => {
  const accepted = localStorage.getItem('accepted')
  const rdspe = localStorage.getItem('rdspe') === 'A'
  const type = localStorage.getItem('typerd')
  console.log(to.path, !accepted, type, rdspe)
  if (isAuthenticated() && !accepted && !to.path.includes('radio/register') && !to.path.includes('agencia/register') && !to.path.includes('anunciante/register')) {
    if (type === '1') {
      next({
        path: '/radio/register'
      })
    }
    if (type === '2') {
      next({
        path: '/agencia/register'
      })
    }
    if (type === '3') {
      next({
        path: '/anunciante/register'
      })
    }
  } else {
    if (to.path.includes('agencia') || to.path.includes('radio') || to.path.includes('anunciante')) {
      if (!isAuthenticated()) {
        localStorage.removeItem('token')
        next({
          path: '/'
        })
      } else if (!rdspe && (!to.path.includes('detail/campaign') && !to.path.includes('profile/user') && !to.path.includes('create/campaign') && !to.path.includes('booking-campaign') && !to.path.includes('campaign-list'))) {
        if (type === '1') {
          next({
            path: '/radio/campaign-list'
          })
        }
        if (type === '2') {
          next({
            path: '/agencia/booking-campaign'
          })
        }
        if (type === '3') {
          next({
            path: '/anunciante/booking-campaign'
          })
        }
      } else {
        next()
      }
    } else if (to.path === '/login' || to.path === '/') {
      if (isAuthenticated()) {
        if (type === '1') {
          next({
            path: '/radio'
          })
        }
        if (type === '2') {
          next({
            path: '/agencia'
          })
        }
        if (type === '3') {
          next({
            path: '/anunciante'
          })
        }
      } else {
        next()
      }
    } else {
      next()
    }
  }
})

export default router
